import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Login from "../components/auth/Login"

const AuthPage = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        emailConfirmation {
          confirmationPage {
            id
            slug
          }
          confirmationLandingPage {
            id
            slug
          }
        }
      }
    }
  `)

  return (
    <Layout hasHero={false}>
      <SEO title="Login or sign up" />
      <Login
        confirmationPage={
          data.strapi.emailConfirmation?.confirmationPage?.slug ||
          data.strapi.emailConfirmation?.confirmationLandingPage?.slug
        }
        // confirmationSuccessPage={
        //   data.strapi.emailConfirmation?.redirectPage?.slug
        // }
      />
    </Layout>
  )
}

// export const query = graphql`
//   query LoginPageQuery {
//     strapi {
//       emailConfirmation {
//         confirmationPage {
//           id
//           slug
//         }
//         # redirectPage {
//         #   id
//         #   slug
//         # }
//       }
//     }
//   }
// `

export default AuthPage
